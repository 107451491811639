/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
import { DistributedAssetsController } from './distributedAssets-controller';
import { DistributedAssetsGridController } from './distributedAssetsGrid-controller';
import { distributedAssetsSearch } from './distributedAssetsSearch-controller';
var dependencies = ['ngMaterial', 'comcast.common.authentication', 'comcast.common.communication', 'comcast.common.navigation', 'ngMessages', 'ui.router', 'comcast-addelivery-constants', 'comcast.common.notification', 'comcast.directives'];
var defaultName = angular.module('comcast.distributedAssets', dependencies).component('distributedAssetsSearch', distributedAssetsSearch).config(['$stateProvider', 'addelivery.state.constants', function ($stateProvider, stateConstants) {
  $stateProvider.state('distributedAssets', {
    url: '/distributedassets',
    views: {
      mainNav: stateConstants.mainNav,
      leftNav: stateConstants.leftNav,
      content: {
        template: require('./distributedAssets-template.html'),
        controller: DistributedAssetsController,
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  }).state('distributedAssetsList', {
    // Specify params that this state can access from the URL
    url: '/list?isci&format&referenceLink&orderDateFrom&orderDateTo&advertiser&destinationId&oldReferenceLink&startDateFrom&startDateTo&endDateFrom&endDateTo&campaign&includeArchives',
    parent: 'distributedAssets',
    // Specify default values for state params
    params: {
      isci: null,
      format: null,
      referenceLink: null,
      orderDate: null,
      advertiser: null,
      destination: null,
      oldReferenceLink: null,
      startDate: null,
      endDate: null,
      campaign: null,
      includeArchives: null,
      sortField: null,
      sortDirection: null
    },
    reloadOnSearch: true,
    views: {
      'distributedAssetContent@distributedAssets': {
        template: '<ui-view name="detailContent" class="detail-content"/>'
      },
      'distributedAssetSearch@distributedAssets': {
        template: '<ui-view name="detailSearch" class="detail-search" />'
      },
      'detailContent@distributedAssetsList': {
        template: require('./distributedAssetsGrid-template.html'),
        controller: DistributedAssetsGridController,
        controllerAs: 'vm'
      },
      'detailSearch@distributedAssetsList': {
        template: '<distributed-assets-search></distributed-assets-search>'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  });
}]).name;
export { defaultName as default, dependencies };